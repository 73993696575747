/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Bariol';
  font-style: normal;
  font-weight: normal;
  src:
    local('Bariol'),
    url('bariol_regular-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Bariol Regular Italic';
  font-style: normal;
  font-weight: normal;
  src:
    local('Bariol Regular Italic'),
    url('bariol_regular_italic-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Bariol Thin Italic';
  font-style: normal;
  font-weight: normal;
  src:
    local('Bariol Thin Italic'),
    url('bariol_thin_italic-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Bariol Thin';
  font-style: normal;
  font-weight: normal;
  src:
    local('Bariol Thin'),
    url('bariol_thin-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Bariol Light';
  font-style: normal;
  font-weight: normal;
  src:
    local('Bariol Light'),
    url('bariol_light-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Bariol Light Italic';
  font-style: normal;
  font-weight: normal;
  src:
    local('Bariol Light Italic'),
    url('bariol_light_italic-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Bariol Bold';
  font-style: normal;
  font-weight: normal;
  src:
    local('Bariol Bold'),
    url('bariol_bold-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Bariol Bold Italic';
  font-style: normal;
  font-weight: normal;
  src:
    local('Bariol Bold Italic'),
    url('bariol_bold_italic-webfont.woff') format('woff');
}
