/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import 'assets/fonts/style.css';

ion-menu-button.md {
  margin: 10px;
  --background: var(--ion-color-primary);
  --color: white;
}

ion-item.np-requirement-item {
  background: black;
}

.header-md::after {
  background-image: none;
}

ion-backdrop {
  background: white !important;
}
.my-custom-modal-css .modal-wrapper {
  max-height: 100%;

  top: 0%;
  position: absolute;
  display: block;
  border-radius: 15px;
  border-style: solid;
  border-width: 4px;
  // border-color: var(--ion-color-secondary);
  // background-color: #FF7C37 !important;
}
.my-custom-modal-css .modal-body {
  background-color: #ff7c37 !important;
}

.swiper-pagination {
  width: 50% !important;
  left: 25% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet {
  transition:
    width 0.2s ease-in-out,
    height 0.2s ease-in-out;
}

.swiper-pagination-bullet-active {
  width: 12px;
  height: 12px;
  background: transparent;
  border: 2px solid var(--bullet-background-active);
}

.swal2-icon.swal2-success {
  border-left-color: var(--ion-color-success) !important;
  border-right-color: var(--ion-color-success) !important;
}
.swal2-icon.swal2-success [class^='swal2-success-line'] {
  background-color: var(--ion-color-success) !important;
}

.swal2-styled.swal2-confirm {
  background-color: var(--ion-color-primary) !important;
}
.swal2-icon.swal2-error {
  border-left-color: var(--ion-color-danger) !important;
  border-right-color: var(--ion-color-danger) !important;
}

.swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
  background-color: var(--ion-color-danger) !important;
}

.my-modal-class {
  .modal-wrapper {
    height: 60%;
    width: 60%;
    border-radius: 15px;
  }
}

.my-modal-classV2 {
  .modal-wrapper {
    height: 90%;
    width: 90%;
    border-radius: 15px;
  }
}

.my-custom-modal-cssV2 .modal-wrapper {
  max-height: 100%;

  top: 0%;
  position: absolute;
  display: block;
  border-radius: 15px;
  border-style: solid;
  border-width: 4px;
  // border-color: var(--ion-color-secondary);
  // color: #FF7C37 !important;
}

.my-custom-class .modal-wrapper {
  border-radius: 15px;
  // border-style: solid;
  // border-width: 4px;
}

.alertCambioAnio {
  --min-height: 200px;
  --min-width: 400px;
}

.alertInicioAnio {
  --min-height: 200px;
  --min-width: 300px;
}

.alertDatosDocentePiar {
  text-align: center;
  --min-width: 350px;
}

.alertAjusteAula {
  text-align: center;
  --min-width: 600px;
}

@media screen and (max-width: 600px) {
  .alertAjusteAula {
    text-align: center;
    --min-width: 350px;
  }
}

//   .my-modal-classV3{
//     .modal-wrapper{
//      height: 40%;
//      width: 40%;
//      border-radius: 15px;
//    }}

.my-modal-classV3 .modal-wrapper {
  min-height: 45%;
  min-width: 55%;
  height: auto;
  width: auto;
  background: #e4e5f8;
  // top: 0%;
  // position: absolute;
  // display: block;
  border-radius: 15px;
  border-style: solid;
  border-width: 4px;
  border-color: var(--ion-color-secondary);
  // color: #FF7C37 !important;
}

@media screen and (max-width: 1000px) {
  .my-modal-classV3 .modal-wrapper {
    min-height: 75%;
    min-width: 75%;
    background: #e4e5f8;
    // top: 0%;
    // position: absolute;
    // display: block;
    border-radius: 15px;
    border-style: solid;
    border-width: 4px;
    border-color: var(--ion-color-secondary);
    // color: #FF7C37 !important;
  }
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}

.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 0.5em;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  box-shadow: inset;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3) !important;
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: #585b8f99 !important;
  outline: 2px solid #585b8f33 !important;
}
.wide-select .alert-wrapper {
  min-width: 30% !important;
  width: 30% !important;
  max-width: 30% !important;
  --min-width: 30% !important;
}

.imgProfeSkalo {
  position: fixed;
  bottom: 0px;
  right: 0px;
  min-width: 300px;
  max-width: 450px;
  margin-top: 20vh;
  //top: 0;
  //width: max-content;
  span {
    position: absolute;
    font-weight: bold;
    cursor: pointer;
    line-height: 21px;
    z-index: 100000;
    background: var(--ion-color-primary);
    color: white;
    border-radius: 29px;
    width: 30px; //40px
    height: 30px; //40px
    font-size: x-large;
    display: flex;
    border: 2px solid white;
    margin: auto;
    ion-icon {
      margin: auto;
    }
  }
  .imgProfeSkaloCloseBtn {
    bottom: 70%;
    right: 10%;
  }
  .imgProfeSkaloHiddenBtn {
    bottom: 70%;
    right: 2%;
  }
  .imgProfeSkaloDesktop {
    max-height: 80vh;
  }
  .imgProfeSkaloMovil {
    display: none;
  }
}
.noImgProfeSkalo {
  display: none;
}
.vDest1 {
  width: 60vw;
}
.ayudaSkaloButton {
  //width: 100%;
  //display: flex;
  //justify-content: center;
  position: fixed;
  bottom: 10px;
  z-index: 10;
  display: inline;
  width: 20vw;
  margin: 0 auto;
  left: 40vw;
  ion-button {
    width: inherit;
  }
}

.terminos-class {
  .alert-wrapper {
    max-width: 70vw;
    min-height: 70vh;
  }

  .alert-message {
    min-height: 50vh;
  }

  div::-webkit-scrollbar,
  .alert-checkbox-group::-webkit-scrollbar {
    width: 0.5em;
    display: block !important;
  }

  div::-webkit-scrollbar-track,
  .alert-checkbox-group::-webkit-scrollbar-track {
    box-shadow: inset;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3) !important;
  }

  div::-webkit-scrollbar-thumb,
  .alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: #585b8f99 !important;
    outline: 2px solid #585b8f33 !important;
  }
}

.modal-informacion-diagnostico {
  --width: 1200px;
}

.classModalActividades {
  --width: 500px;
  --min-width: 250px;
  // --min-height: 250px;
  --height: 500px;
  --border-radius: 15px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f4f5f8;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #dddddd;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
}

.custom-select-popover-content {
  max-width: 500px;
}

.modal-checkbox {
  border-radius: 10px;
  ion-accordion-group {
    ion-accordion {
      #subitem {
        .ion-accordion-toggle-icon {
          color: white;
        }
      }
    }
  }
}

ion-accordion-group {
  ion-accordion {
    #subitem {
      .ion-accordion-toggle-icon {
        color: white;
      }
    }
  }
}

.alert-wrapper {
  // --min-width:350px
  --width: 450px;
  --max-width: 1500px;
  border-radius: 9px;
}

.modal-download-piar {
  --min-height: 95vh;
  --min-width: 80vw;
}

.toast-container.toast-top-full-width .ngx-toastr {
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.toast-ia {
  max-width: 380px;
  padding: 15px;
  border-radius: 10px;
  margin-top: 15px;
  color: white;
}
.toast-ia-mensaje {
  font-size: 16px !important;
  color: white;
}

.modal-ajustes-skalo-piar {
  --width: 50%;
  --height: 65%;
  --border-radius: 10px;
  display: flex;
  flex-direction: column;

  .custom-scrollbar {
    // For Webkit browsers (Chrome, Safari, Edge)
    &::-webkit-scrollbar {
      width: 8px; // Adjust this value to make the scrollbar thinner
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888; // Change this to your desired color
      border-radius: 4px; // Adjust this value for the desired border radius
      border: 2px solid transparent;
      background-clip: content-box;
    }

    // For Firefox
    scrollbar-width: thin;
    scrollbar-color: #888 transparent; // First color is the thumb, second is the track

    // Hide default scrollbar for IE, Edge, and Firefox
    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox

    // Custom scrollbar for Firefox
    & {
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: #888 transparent;
    }
  }
}
